<template>
  <div class="hero">
    <div class="hero-content">
      <h1>{{ text }}</h1>
      <div class="hero-content_actions">
        <Button @click="SignIn()" v-if="!isAuth()" type="submit" text="Registrer" color="grey"></Button>
        <Button @click="SignIn()" v-if="!isAuth()" type="submit" text="Logg inn" color="grey"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'Hero',
  components: {
    Button
  },
  props: {
    image: String,
    text: String
  },
  methods: {
    isAuth() {
      return this.$auth.isAuthenticated();
    },
    async SignIn() {
      await this.$auth
        .login()
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(`Error during authentication: ${error}`);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.hero {
  position: relative;
  margin-top: -87px;
  img {
    max-width: 100%;
  }
  background-image: url('../assets/header_image2.png');
  background-position: center;
  display: block;
  width: 100%;
  padding-bottom: 120%;
  @media (min-width: $breakpoint-tablet) {
    padding-bottom: 80%;
  }
  @media (min-width: $breakpoint-desktop) {
    padding-bottom: 50%;
  }
  background-size: cover;
  z-index: 1;
}
.hero-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items:center;
  margin-top: 12rem;
  @media (max-width: $breakpoint-mobile) {
    margin-top: 7rem;
  }
  &_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      font-weight: 500;
    }
    button:not(:last-child) {
      margin-right: 1rem;
    }
  }
  h1 {
    font-size: 40px;
    color: white;
    text-align: center;
    width: 50%;
    @media (max-width: $breakpoint-mobile) {
      font-size: 22px;
      width: 95%;
    }
  }
  button {
    position: relative;
    z-index: 999999;
  }
}
</style>
