<template>
  <section class="services">
    <row container :gutter="20">
      <column :xs="12" :md="3" :lg="3">
        <div class="services-item">
          <img alt="Booking" src="../assets/booking.png" />
          <h2>Booking</h2>
          <p>Enkel administrasjon og oversikt over bruken på hytta</p>
        </div>
      </column>
      <column :xs="12" :md="3" :lg="3">
        <div class="services-item">
          <img alt="Sjekklister" src="../assets/sjekklister.png" />
          <h2>Sjekklister</h2>
          <p>Full oversikt over alle aksjonspunkter</p>
        </div>
      </column>
      <column :xs="12" :md="3" :lg="3">
        <div class="services-item">
          <img alt="Tjenester" src="../assets/tjenester.png" />
          <h2>Marked</h2>
          <p>Gode tilbud for hytteelskere</p>
        </div>
      </column>
      <column :xs="12" :md="3" :lg="3">
        <div class="services-item">
          <img alt="Tjenester" src="../assets/hyttebok.png" />
          <h2>Sosialt</h2>
          <p>Skap deres egen hyttebok med bilder og tekst</p>
        </div>
      </column>
    </row>
  </section>
</template>

<style lang="scss" scoped>
.services {
  background-color: #406862;
  @include gutter-padding;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media (min-width: $breakpoint-mobile) {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }
}
.services-item {
  background-color: #324544;
  opacity: 0.8;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  img {
    margin-bottom: 1.5rem;
    max-height: 200px;
  }
  h2 {
    color: white;
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  p {
    text-align: center;
    color: white;
  }
}
</style>
