<template>
  <Hero
    image="header_image.png"
    text="Tjenesten som forenkler bruken av hytta – og stimulerer aktivitet i by og bygd"
  ></Hero>
  <section class="app-demo">
    <div class="app-demo_images">
      <img alt="Mobile app example 2" src="../assets/iPhone-13-Pro-Max-2.png" />
      <img alt="Mobile app example 1" src="../assets/iPhone-13-Pro-Max.png" />
    </div>
  </section>
  <section class="app-demo-slogan">
    <h2>Løsningen for deg som liker å bruke tiden riktig</h2>
  </section>
  <Services></Services>
  <!-- <section class="coming-soon">
    <row container :gutter="20">
      <column :xs="12" :md="6" :lg="6">
        <div class="coming-soon_title">
          <BookClosed />
          <h2>Hytteboka</h2>
          <span class="coming-soon-pill">Kommer</span>
        </div>
        <p><strong>Mye skjer på Hytta.</strong> Vi ønsker å senke terskelen for at private minner og opplevelser skal dokumenteres.
          I Hytteboka kan man skrive innlegg, legge ut bilder, kommentere og like innleggene som legges ut.
          Hytteboka er et eget avgrenset sosialt medium hvor innholdet kun er delt og tilgjengelig for Hyttas brukere.</p>
      </column>
      <column :xs="12" :md="6" :lg="6">
        <img alt="Mobile app example 2" src="../assets/iphone-pro-longscroll.png" />
      </column>
    </row>
  </section> -->
</template>

<script>
import Hero from '@/components/Hero';
import Services from '@/components/Services';
// import BookClosed from '@/assets/bookClosed.svg';

export default {
  name: 'Home',
  components: {
    Hero,
    Services,
    // BookClosed
  }
};
</script>

<style lang="scss" scoped>
.app-demo {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  z-index: 10;
  padding-top: 2rem;
  &_images {
    position: relative;
    @media (min-width: $breakpoint-mobile) {
      margin-bottom: -200px;
    }
    z-index: 10;
  }
  img {
    width: 150px;
    @media (min-width: $breakpoint-mobile) {
      width: 250px;
    }
    margin-bottom: 2rem;
  }
  img:not(:last-child) {
    margin-right: 2rem;
  }
}
.app-demo:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: radial-gradient(69.66% 26.41% at 49.48% -5.45%, rgba(31, 51, 51, 0.2) 0%, rgba(31, 51, 51, 0) 100%),
    linear-gradient(180deg, #CFDCD8 0%, #E7F4F0 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.app-demo-slogan {
  padding: 3rem;
  @media (min-width: $breakpoint-mobile) {
    padding: 5rem;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    @media (min-width: $breakpoint-mobile) {
      font-size: 32px;
    }
  }
}
.coming-soon {
  background-color: $hytta;
  color: white;
  padding: 3rem 15rem 0 15rem;
  @media (max-width: $breakpoint-mobile) {
    padding: 3rem 1rem 0 1rem;
  }
  padding-top: 8rem;
  &_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;
    svg {
      margin-right: 1rem;
    }
    h2 {
      font-size: 32px;
      margin-bottom: 0;
      line-height: 32px;
    }
    .coming-soon-pill {
      border-radius: 20px;
      text-transform: uppercase;
      padding: .25rem .5rem;
      color: black;
      background-color: #21FFCB;
      margin-left: 1rem;
      letter-spacing: 1.5px;
      font-weight: 500;
    }
  }
  p {
    font-size: 14px;
    line-height: 28px;
    @media (min-width: $breakpoint-mobile) {
      font-size: 14px;
      line-height: 18px;
    }
    @media(min-width: $breakpoint-tablet) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  img {
    width: 100%;
    @media(min-width: $breakpoint-tablet) {
      margin-top: -150px;
    }
    margin-bottom: -4px;
  }
}
</style>
